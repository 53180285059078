<template>
  <div class="post">
    <!-- <pageHead>
      <div slot="right" class="right">
        <searchBox
          :placeholder="placeholder"
          @click.native="toSearch"
          disabled
        />
      </div>
    </pageHead> -->
    <pageHead title="我的发帖" />
    <!-- <van-tabs
      v-if="false"
      v-model="active"
      title-active-color="#FF4533"
      title-inactive-color="#999999"
      @click="updateList"
    >
      <van-tab
        v-for="(item, index) in tabOptions"
        :key="index"
        :title="item.title"
        :disabled="tabDisabled"
      />
    </van-tabs> -->
    <div
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadMore"
      class="post_content"
      ref="postContent">
      <topic
        :showCheckStatus="listQueryParams.checkStatus"
        @like="like($event, index)"
        @favorite="favorite($event, index)"
        v-for="(item, index) in topicData"
        :key="item.id"
        :data="item"
        :showOptions="!listQueryParams.checkStatus"
        :avatar="getUserAvatar"
        class="topicItem"
        @userInfoClick="handleToUserDetail($event,item)"
        @share="handleShare"
        @originalClick="handleTopicClick(item, index)"
        @click="handleTopicClick($event, index)"
        @comment="handleComment"
        @follow="handleFollow(item, index)"
        @tagClick="handleTagClick"
        @handleNewActive="handleNewActive"
      />
      <no-more
        v-if="!listQueryParams.hasNextPage"
        text="没有更多数据"
        class="no-more"
      />
      <van-empty v-if="noData" description="暂无数据" />
    </div>
    <commentToolDialog
      :show-dialog.sync="showDialog"
      :isAnonymousComment.sync="isAnonymousComment"
      v-model="commentContent"
      hide-relation
      hide-img
      @publish="handlePublishComment"
    />
    <van-action-sheet
      v-model="showAction"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="handleSelect"
    />
    <div v-if="false" class="addBtn">
      <img src="/images/appMainView/add.png" @click="handlerAdd" />
      <div class="wave wave0"></div>
    </div>
  </div>
</template>

<script>
import {articleRemove} from "@/api/article";
import {addThumbsup, removeThumbsup} from "@/api/thumbsup";
import {addFavorites, removeFavorites, myPosting} from "@/api/favorites";
import {addComment} from "@/api/comment";

import pageHead from "@/components/pageHead";
import topic from "@/components/topicItem";
import NoMore from "@/components/uitls/NoMore";
import commentToolDialog from "@/components/commentToolDialog";
// import searchBox from "@/components/searchBox";
import {attAdd, followRemove} from "@/api/att";

const ENTITY_TYPE_ARTICLE = 2;

export default {
  components: {
    pageHead,
    topic,
    NoMore,
    commentToolDialog,
    // searchBox,
  },
  data() {
    return {
      placeholder: "搜索我的帖子",
      tabOptions: [
        {title: "全部", key: "all"},
        {title: "原创", key: "original"},
        {title: "转发", key: "forward"},
        {title: "审核", key: "examine"},
      ],
      active: 0,
      showAction: false,
      actions: [
        {name: "查看详情", key: "detail"},
        // { name: "收藏帖子", key: "collect" },
        {name: "删除", color: "#FF4533", key: "delete"},
      ],
      isShowOperation: false,
      topicData: [],
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
        sortType: 1,
      },
      currentTopic: {},
      showDialog: false,
      isAnonymousComment: false,
      commentContent: "",
      tabDisabled: false,
      noData: false,
    };
  },
  computed: {
    getUserAvatar() {
      return this.$store.getters.user.avatar;
    },
  },
  methods: {
    handleNewActive (item) {
      const index = this.topicData.findIndex((i) => {
        return i.articleId === item.articleId
      })
      this.topicData[index].articleVoteVO = item
    },
    async handleFollow(data) {
      const {id, myFollow} = data;
      if (myFollow) {
        await followRemove(id);
        data.commonFollowCount--;
      } else {
        await attAdd(id);
        data.commonFollowCount++;
      }
      data.myFollow = !myFollow;
    },
    handleTopicClick(res, index) {
      if (!this.listQueryParams.checkStatus) {
        this.showAction = true;
        this.currentTopic = {res, index};
      }
    },
    // 点击搜索框跳转到搜索页
    // toSearch() {
    //   this.$router.push({
    //     name: "search",
    //     query: {
    //       placeholder: this.placeholder,
    //       type: "person",
    //     },
    //   });
    // },
    // 切换帖子类型选择
    // updateList() {
    //   this.topicData = [];
    //   this.listQueryParams = {
    //     pageNum: 1,
    //     pageSize: 10,
    //     hasNextPage: true,
    //     isVote: 0,
    //   };
    //   switch (this.tabOptions[this.active].key) {
    //     case "original":
    //       this.listQueryParams.quoteType = 1;
    //       break;
    //     case "forward":
    //       this.listQueryParams.quoteType = 2;
    //       break;
    //     case "examine":
    //       this.listQueryParams.checkStatus = true;
    //       break;
    //     default:
    //       break;
    //   }
    //   if (this.$refs.postContent.scrollTop == 0) {
    //     this.loadList();
    //   }
    // },
    // 下拉刷新
    loadMore() {
      this.loadList();
    },
    // 列表接口
    async loadList() {
      this.tabDisabled = true;
      let listQueryParams = this.listQueryParams;
      listQueryParams.userId = this.$store.getters.user.user_id;
      if (listQueryParams.hasNextPage) {
        let res = {};
        // if (listQueryParams.checkStatus) {
        //   const { pageNum, pageSize } = listQueryParams;
        //   res = await getCheckArticle({ pageNum, pageSize });
        // } else {
        //   res = await getByPage(this.listQueryParams);
        // }
        res = await myPosting(this.listQueryParams);
        listQueryParams.hasNextPage = res.current < res.pages;
        listQueryParams.pageNum = listQueryParams.pageNum + 1;
        let resData = this.topicData;
        res.records.forEach((item) => {
          let count = resData.filter(
            (innerItem) => innerItem.articleId === item.articleId
          );
          if (count.length === 0) {
            resData.push(item);
          }
        });
        this.topicData = resData;
        if (this.topicData.length === 0) {
          this.noData = true;
        }
        this.tabDisabled = false;
      }
    },
    handleTagClick(tag) {
      this.$router.push({name: "searchTag", query: {tag: tag}});
    },
    like(res, index) {
      // 取消点赞
      if (res.isThumbsUp) {
        removeThumbsup({
          entityId: res.articleId,
          entityType: 1,
        }).then(() => {
          let data = this.topicData[index];
          this.topicData[index].isThumbsUp = !res.isThumbsUp;
          let count = data.articleStatisticVO.thumbsUpCount - 1;
          if (count < 0) count = 0;
          data.articleStatisticVO.thumbsUpCount = count;
        });
      } else {
        // 添加点赞
        addThumbsup({
          entityId: res.articleId,
          entityType: 1,
        }).then(() => {
          let data = this.topicData[index];
          this.topicData[index].isThumbsUp = !res.isThumbsUp;
          if (!data.articleStatisticVO) {
            data.articleStatisticVO = {};
          }
          data.articleStatisticVO.thumbsUpCount =
            data.articleStatisticVO.thumbsUpCount + 1;
        });
      }
    },

    favorite(res, index) {
      console.log(res);
      // 取消收藏
      if (res.isFavorites) {
        removeFavorites({
          entityId: res.articleId,
          entityType: 1,
        }).then(() => {
          let data = this.topicData[index];
          this.topicData[index].isFavorites = !res.isFavorites;
          let count = data.articleStatisticVO.favoritesCount - 1;
          if (count < 0) count = 0;
          data.articleStatisticVO.favoritesCount = count;
        });
      } else {
        // 添加收藏
        addFavorites({
          entityId: res.articleId,
          entityType: 1,
        }).then(() => {
          let data = this.topicData[index];
          this.topicData[index].isFavorites = !res.isFavorites;
          if (!data.articleStatisticVO) {
            data.articleStatisticVO = {};
          }
          data.articleStatisticVO.favoritesCount =
            data.articleStatisticVO.favoritesCount + 1;
        });
      }
    },
    /**
     * 分享事件
     */
    handleShare({id: quoteId} = {}) {
      this.$router.push({name: "createTopic", query: {quoteId}});
    },
    // 个人中心
    handleToUserDetail(value,item) {
      const userId = item.userBasicVO.userId
      this.$router.push({
        name: "userInfoDetail",
        params: {userId}
      });
    },
    // 帖子操作
    handleSelect(action) {
      console.log(action);
      const {res, index} = this.currentTopic;
      console.log(index);
      if (action.key === "detail") {
        this.$router.push({
          name: "topicDetail",
          query: {id: res.articleId},
        });
      }
      if (action.key === "collect") {
        if (!res.favoritesFlag) {
          addFavorites({
            articleId: res.id,
          })
            .then(() => {
              let data = this.topicData[index];
              this.topicData[index].favoritesFlag = !res.favoritesFlag;
              if (!data.relationVO) {
                data.relationVO = {};
              }
              data.relationVO.favoriteCount = data.relationVO.favoriteCount + 1;
              this.$toast.success({
                message: "收藏成功~",
                position: 'top'
              });
            })
            .catch(() => {
              this.$toast.fail({
                message: "收藏失败！",
                position: 'top'
              });
            });
        } else {
          this.$toast({
            message: "已经收藏过此贴",
            position: 'top'
          });
        }
      } else if (action.key === "delete") {
        this.$dialog
          .confirm({
            message: "确认删除该帖子吗？",
          })
          .then(() => {
            // const ids = [];
            // ids.push(res.id);
            articleRemove({articleId: res.articleId}).then(() => {
              // this.listQueryParams = {
              //   pageNum: 1,
              //   pageSize: 10,
              //   sortType: 1,
              //   hasNextPage: true,
              // };
              // this.loadList();
              this.topicData.splice(index, 1);
            });
          })
          .catch(() => {
            console.log("cancle");
          });
      }
      this.showAction = false;
    },
    /**
     * 添加评论
     * @param item
     */
    handleComment(item) {
      this.showDialog = true;
      this.commentParams = item;
    },
    async handlePublishComment() {
      const {
        commentContent: content,
        commentParams: {articleId: entityId},
        isAnonymousComment: isAnonymous,
      } = this;
      const entityType = ENTITY_TYPE_ARTICLE;
      if (!content.trim()) {
        this.$notify({message: "评论内容不能为空", type: "danger"});
        return;
      }
      await addComment({content, entityId, entityType, isAnonymous});
      this.showDialog = false;
      this.commentParams = {};
      this.commentContent = "";
      this.topicData = this.topicData.map((item) => {
        if (item.articleId !== entityId) {
          return item;
        }
        const {articleStatisticVO} = item;
        return {
          ...item,
          articleStatisticVO: {
            ...articleStatisticVO,
            commentCount: articleStatisticVO.commentCount + 1,
          },
        };
      });
    },
    // 新增帖子
    handlerAdd() {
      this.$router.push({name: "createTopic"});
    },
    handleFocus() {
    },
  },
};
</script>

<style lang="scss" scoped>
.post {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .right {
    width: 300px;
  }

  .van-tabs {
    background-color: white;

    ::v-deep .van-tabs__wrap {
      height: 35px;

      .van-tabs__nav {
        width: 240px;

        .van-tab {
          font-size: 14px;
        }
      }
    }
  }

  .post_content {
    overflow-y: auto;
    flex: 1;
    background-color: #f4f4f4;

    .topicItem {
      margin: 10px 0;
    }

    .no-more {
      margin-top: 10px;
    }
  }

  .van-action-sheet {
    margin-bottom: 57px;
    color: $mainTextColor;
    background-color: transparent;
    font-size: 14px;

    .van-action-sheet__content {
      border-radius: 6px;
      width: 360px;
      margin: 0 auto;

      .van-action-sheet__item {
        background-color: $backgroundGray;
        border-bottom: 1px solid rgba(17, 17, 17, 0.5);
        height: 56px;
      }
    }

    .van-action-sheet__gap {
      background-color: transparent;
    }

    .van-action-sheet__cancel {
      border-radius: 6px;
      height: 56px;
      width: 360px;
      margin: 0 auto;
    }
  }

  .addBtn {
    position: fixed;
    width: 80px;
    bottom: 80px;
    right: 20px;
    z-index: 1001;

    img {
      width: 55px;
      z-index: 20;
      position: relative;
      left: 12px;
      top: 3px;
    }

    .wave {
      position: absolute;
      top: calc((100% - 30px) / 2);
      left: calc((100% - 30px) / 2);
      width: 30px;
      height: 30px;
      border-radius: 300px;
    }

    .wave0 {
      z-index: 2;
      background-size: auto 106%;
      animation: w 2s forwards infinite;
    }

    @keyframes w {
      0% {
        background: rgba(255, 69, 51, 0.5);
        top: calc((100% - 30px) / 2);
        left: calc((100% - 30px) / 2);
        width: 30px;
        height: 30px;
      }
      100% {
        background: transparent;
        top: calc((100% - 120px) / 2);
        left: calc((100% - 120px) / 2);
        width: 120px;
        height: 120px;
      }
    }
  }
}

::v-deep .van-field__control {
  -webkit-text-fill-color: $remarkColor !important;
}
</style>

